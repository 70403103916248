import React from "react";

const Svg404 = () => {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M315 127C334 148.25 348.5 166.5 370 186.5C391.5 206.5 420.25 228.25 437.25 259.75C454.25 291.5 459.75 332.75 438.25 353C416.75 373.25 368.5 372.25 332.5 386.5C296.75 400.75 273.25 430 244.75 439C216.25 448.25 182.25 437 155 418C127.75 398.75 106.75 371.75 89.2504 342.5C71.5004 313 57.0004 281.5 55.5004 249C53.7504 216.5 64.7504 183 80.0004 149.5C95.2504 116 114.5 82.4999 143.5 67.2499C172.5 51.9999 211.25 55.2499 242.25 68.7499C273.25 82.2499 296.25 106 315 127Z"
        fill="#03AFFF"
      />
      <path
        d="M81.7939 397.057L89.2242 399.337L100.774 368.963L89.8073 365.597L81.7939 397.057Z"
        fill="#FFB8B8"
      />
      <path
        d="M101.626 411.309L78.2727 404.142L80.7954 394.238L96.0288 398.913C98.1823 399.574 100.008 401.147 101.105 403.286C102.202 405.424 102.481 407.954 101.878 410.318L101.626 411.309Z"
        fill="#2F2E41"
      />
      <path
        d="M170.43 403.445L178.145 403.444L181.814 370.777L170.427 370.778L170.43 403.445Z"
        fill="#FFB8B8"
      />
      <path
        d="M193.026 411.307L168.778 411.309L168.777 401.025L184.594 401.024C185.702 401.023 186.798 401.263 187.821 401.728C188.844 402.193 189.773 402.874 190.556 403.734C191.339 404.593 191.96 405.613 192.384 406.736C192.808 407.859 193.026 409.062 193.026 410.277L193.026 411.307V411.307Z"
        fill="#2F2E41"
      />
      <path
        d="M113.095 267.825L95.3364 315.579L167.852 333.461L168.777 306.743L135.849 268.245L113.095 267.825Z"
        fill="#B3B3B3"
      />
      <path
        d="M152.439 193.433C160.472 188.613 163.424 177.558 159.033 168.741C154.642 159.923 144.57 156.683 136.537 161.503C128.505 166.323 125.552 177.378 129.944 186.195C134.335 195.013 144.406 198.253 152.439 193.433Z"
        fill="#FFB8B8"
      />
      <path
        d="M156.332 265.182L123.509 250.434L120.587 229.282C119.726 223.099 121.07 216.791 124.339 211.668C127.608 206.545 132.55 203.003 138.137 201.778L142.479 200.821C146.046 200.037 149.733 200.221 153.22 201.356C156.707 202.491 159.889 204.544 162.489 207.336C165.037 210.007 166.941 213.33 168.034 217.006C169.126 220.683 169.372 224.599 168.749 228.405C168.259 231.667 167.054 234.749 165.238 237.385C154.926 252.187 156.25 264.491 156.265 264.613L156.332 265.182Z"
        fill="#F9FE19"
      />
      <path
        d="M169.698 320.343L153.248 291.627L158.429 239.391L151.272 200.323L151.652 200.334C155.815 200.446 159.779 202.312 162.723 205.545C165.667 208.778 167.365 213.13 167.466 217.699L174.429 255.982L169.698 320.343Z"
        fill="#2F2E41"
      />
      <path
        d="M170.073 397.269C169.38 397.269 168.708 397.005 168.172 396.522C167.636 396.04 167.268 395.369 167.131 394.622L159.925 355.286C158.608 348.079 156.129 341.188 152.605 334.938L132.27 298.83C132.169 298.651 132.024 298.505 131.853 298.41C131.681 298.315 131.489 298.273 131.297 298.29C131.105 298.307 130.921 298.381 130.766 298.505C130.61 298.629 130.488 298.798 130.415 298.993L96.9818 387.151C96.7131 387.856 96.2302 388.437 95.6153 388.795C95.0004 389.153 94.2915 389.266 93.6092 389.114L84.6781 387.116C83.913 386.948 83.2378 386.459 82.7967 385.752C82.3556 385.046 82.1835 384.178 82.3171 383.334L89.6029 336.203C91.0892 326.596 93.6216 317.22 97.1457 308.276L121.82 245.681L158.234 258.808L160.637 286.796L177.462 331.515C178.624 334.603 179.319 337.879 179.519 341.214L182.627 392.897C182.676 393.76 182.415 394.609 181.901 395.263C181.387 395.918 180.66 396.326 179.875 396.4L170.321 397.258C170.238 397.265 170.155 397.269 170.073 397.269Z"
        fill="#2F2E41"
      />
      <path
        d="M103.646 333.15L92.1021 321.122L113.919 228.777C115.303 222.971 117.898 217.598 121.499 213.082C125.101 208.566 129.609 205.031 134.669 202.756L135.097 202.565L133.931 258.768L103.646 333.15Z"
        fill="#2F2E41"
      />
      <path
        d="M140.625 195.598C136.53 191.707 135.285 184.449 137.794 179.098C140.303 173.747 146.303 170.866 151.434 172.549C156.266 174.135 161.759 168.847 161.17 163.177C160.581 157.507 154.156 153.813 149.781 156.63C146.468 155.171 142.841 154.793 139.336 155.542C135.832 156.291 132.6 158.135 130.028 160.853C124.887 166.38 122.938 175.416 125.245 183.014C127.553 190.612 133.994 196.364 140.625 195.598Z"
        fill="#2F2E41"
      />
      <path
        d="M126.125 343.476C125.923 343.561 125.712 343.621 125.497 343.653L99.1417 347.692C98.5041 347.792 97.8544 347.651 97.2995 347.292C96.7447 346.933 96.3176 346.377 96.0887 345.716L73.4026 280.92C73.2557 280.5 73.1929 280.051 73.2185 279.602C73.2441 279.154 73.3575 278.717 73.5509 278.321C73.7444 277.924 74.0134 277.579 74.3395 277.307C74.6657 277.035 75.0414 276.844 75.441 276.746L75.4411 276.746L100.226 270.675C100.417 270.628 100.602 270.554 100.776 270.454L124.883 256.619C125.069 256.512 125.266 256.428 125.469 256.369L151.999 248.606C152.678 248.405 153.401 248.489 154.028 248.84C154.654 249.19 155.14 249.784 155.392 250.505L175.172 307.004C175.346 307.496 175.403 308.029 175.338 308.552C175.272 309.075 175.087 309.571 174.798 309.993L167.957 320.077C167.635 320.552 167.197 320.916 166.694 321.128L151.744 327.435C151.637 327.48 151.533 327.534 151.433 327.597L126.52 343.27C126.394 343.349 126.261 343.418 126.125 343.476V343.476Z"
        fill="white"
      />
      <path
        d="M138.387 257.243L135.018 258.23L126.09 260.843L126.074 260.847L113.851 267.863L109.316 270.466L109.313 270.467L102.501 274.377L102.314 274.423L95.237 276.155L91.8166 276.995L77.8662 280.412L99.7666 342.964L114.142 340.762L117.667 340.221L125.18 339.069L125.267 339.056L149.409 323.869L164.097 317.672L170.804 307.787L151.74 253.337L138.387 257.243Z"
        fill="#E6E6E6"
      />
      <path
        d="M154.881 262.307L82.2544 292.944L83.4981 296.497L156.125 265.86L154.881 262.307Z"
        fill="white"
      />
      <path
        d="M159.026 274.149L86.3999 304.786L87.6436 308.338L160.27 277.701L159.026 274.149Z"
        fill="white"
      />
      <path
        d="M167.401 298.068L94.7744 328.705L96.0181 332.257L168.645 301.62L167.401 298.068Z"
        fill="white"
      />
      <path
        d="M138.387 257.243L160.13 319.346L156.894 320.711L135.018 258.23L138.387 257.243Z"
        fill="white"
      />
      <path
        d="M113.852 267.863L109.387 281.499L107.839 286.229L104.228 297.265L103.454 299.625L102.68 301.995L101.66 305.108L94.3408 327.467L92.438 322.031L98.4058 303.799L99.7559 299.671L99.9539 299.068L101.551 294.187L103.565 288.032L105.113 283.301L109.314 270.467L109.316 270.467L113.852 267.863Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M149.935 323.647L149.409 323.869L125.267 339.057L125.18 339.069L102.314 274.423L102.501 274.377L109.314 270.467L109.315 270.467L113.852 267.863L126.075 260.847L126.09 260.843L149.935 323.647Z"
        fill="black"
      />
      <path
        d="M126.234 343.787C126.01 343.881 125.777 343.947 125.539 343.984L99.1835 348.022C98.478 348.133 97.7591 347.977 97.1451 347.579C96.5311 347.182 96.0585 346.567 95.8051 345.836L73.119 281.039C72.9565 280.575 72.887 280.078 72.9154 279.582C72.9437 279.085 73.0692 278.601 73.2833 278.163C73.4974 277.725 73.795 277.342 74.1559 277.042C74.5169 276.741 74.9326 276.529 75.3748 276.421L100.16 270.35C100.325 270.309 100.485 270.244 100.635 270.159L124.742 256.324C124.948 256.205 125.166 256.112 125.39 256.047L151.921 248.284C152.672 248.062 153.472 248.154 154.166 248.542C154.859 248.931 155.397 249.587 155.675 250.386L175.456 306.884C175.648 307.429 175.711 308.018 175.638 308.597C175.566 309.176 175.361 309.725 175.042 310.192L168.201 320.276C167.845 320.801 167.36 321.205 166.803 321.439L151.853 327.746C151.76 327.785 151.67 327.832 151.584 327.886L126.671 343.559C126.531 343.647 126.385 343.723 126.234 343.787V343.787ZM75.3659 277.813C74.8864 278.015 74.4998 278.419 74.2911 278.934C74.0824 279.449 74.0686 280.035 74.2528 280.561L96.9389 345.358C97.0947 345.807 97.3852 346.185 97.7626 346.429C98.14 346.674 98.582 346.77 99.0157 346.701L125.372 342.663C125.617 342.625 125.853 342.537 126.067 342.402L150.98 326.729C151.12 326.641 151.266 326.565 151.417 326.501L166.367 320.195C166.71 320.05 167.008 319.802 167.226 319.48L174.067 309.396C174.264 309.109 174.39 308.771 174.434 308.415C174.479 308.06 174.44 307.697 174.322 307.363L154.541 250.864C154.37 250.373 154.04 249.97 153.614 249.731C153.187 249.492 152.696 249.435 152.233 249.572L125.704 257.335C125.566 257.376 125.432 257.433 125.305 257.505L101.198 271.34C100.954 271.48 100.694 271.584 100.425 271.651L75.6395 277.722C75.5464 277.745 75.4549 277.775 75.3659 277.813Z"
        fill="#E6E6E6"
      />
      <path
        d="M95.237 276.155L117.667 340.221L114.142 340.762L91.8164 276.995L95.237 276.155Z"
        fill="white"
      />
      <path
        d="M101.688 302.015C102.582 302.015 103.306 301.22 103.306 300.239C103.306 299.258 102.582 298.463 101.688 298.463C100.795 298.463 100.07 299.258 100.07 300.239C100.07 301.22 100.795 302.015 101.688 302.015Z"
        fill="#3F3D56"
      />
      <path
        d="M159.055 303.908C159.949 303.908 160.673 303.113 160.673 302.132C160.673 301.151 159.949 300.356 159.055 300.356C158.162 300.356 157.438 301.151 157.438 302.132C157.438 303.113 158.162 303.908 159.055 303.908Z"
        fill="#F9FE19"
      />
      <path
        d="M159.71 270.055C161.547 275.3 159.337 292.681 158.56 298.26C158.535 298.437 158.468 298.604 158.366 298.744C158.264 298.883 158.13 298.99 157.978 299.054C157.826 299.119 157.661 299.137 157.5 299.109C157.339 299.081 157.187 299.006 157.061 298.893C153.08 295.32 140.834 284.037 138.998 278.792C138.476 277.299 138.226 275.708 138.263 274.11C138.301 272.511 138.625 270.936 139.217 269.475C139.809 268.013 140.658 266.694 141.714 265.593C142.771 264.492 144.014 263.63 145.374 263.056C146.734 262.482 148.184 262.208 149.64 262.25C151.097 262.291 152.532 262.647 153.863 263.296C155.194 263.946 156.396 264.878 157.399 266.037C158.402 267.197 159.188 268.562 159.71 270.055V270.055Z"
        fill="#F9FE19"
      />
      <path
        d="M149.354 281.02C152.673 281.02 155.364 278.067 155.364 274.424C155.364 270.781 152.673 267.827 149.354 267.827C146.035 267.827 143.345 270.781 143.345 274.424C143.345 278.067 146.035 281.02 149.354 281.02Z"
        fill="white"
      />
      <path
        d="M141.635 260.19C141.878 259.211 142.314 258.301 142.912 257.526C143.51 256.751 144.256 256.13 145.096 255.706C145.937 255.281 146.852 255.064 147.777 255.07C148.702 255.076 149.615 255.304 150.451 255.739L161.179 245.094L168.663 250.673L153.32 265.472C152.561 266.965 151.33 268.101 149.859 268.664C148.389 269.228 146.781 269.179 145.341 268.528C143.9 267.877 142.727 266.669 142.045 265.132C141.362 263.595 141.216 261.837 141.635 260.19V260.19Z"
        fill="#FFB8B8"
      />
      <path
        d="M158.921 263.665L150.512 251.137L169.683 237.669L155.942 216.146C154.82 214.388 154.327 212.236 154.56 210.104C154.792 207.972 155.733 206.009 157.203 204.591C158.672 203.173 160.566 202.4 162.522 202.42C164.478 202.44 166.359 203.251 167.804 204.698L185.009 221.929C186.648 223.57 187.92 225.605 188.725 227.874C189.53 230.143 189.847 232.585 189.652 235.011C189.456 237.437 188.753 239.78 187.597 241.859C186.441 243.937 184.863 245.695 182.987 246.994L158.921 263.665Z"
        fill="#2F2E41"
      />
      <path
        d="M93.0353 296.534C93.7955 295.955 94.4308 295.2 94.8965 294.323C95.3622 293.445 95.647 292.466 95.7308 291.455C95.8146 290.444 95.6953 289.425 95.3814 288.469C95.0675 287.514 94.5666 286.646 93.914 285.926L99.473 271.271L92.1838 265.391L84.5982 286.207C83.5692 287.491 83.0049 289.144 83.0123 290.854C83.0196 292.564 83.5981 294.211 84.6381 295.484C85.678 296.757 87.1073 297.567 88.6549 297.76C90.2026 297.954 91.7611 297.518 93.0353 296.534V296.534Z"
        fill="#FFB8B8"
      />
      <path
        d="M96.7914 281.654L83.6445 278.294L93.4416 247.284C95.1298 241.933 97.6205 236.93 100.811 232.48L116.794 210.17C118.256 208.13 120.395 206.808 122.742 206.495C125.09 206.182 127.455 206.903 129.319 208.501C131.183 210.098 132.395 212.441 132.688 215.017C132.982 217.593 132.333 220.191 130.884 222.243L113.149 247.369L96.7914 281.654Z"
        fill="#2F2E41"
      />
      <path
        d="M257.006 412H25.6073C25.4463 412 25.2918 411.93 25.1779 411.805C25.064 411.68 25 411.51 25 411.333C25 411.156 25.064 410.987 25.1779 410.862C25.2918 410.737 25.4463 410.667 25.6073 410.667H257.006C257.168 410.667 257.322 410.737 257.436 410.862C257.55 410.987 257.614 411.156 257.614 411.333C257.614 411.51 257.55 411.68 257.436 411.805C257.322 411.93 257.168 412 257.006 412Z"
        fill="#CCCCCC"
      />
      <path
        d="M346.764 411.667H341.298V60C341.298 59.2044 341.586 58.4413 342.099 57.8787C342.611 57.3161 343.307 57 344.031 57C344.756 57 345.451 57.3161 345.964 57.8787C346.477 58.4413 346.764 59.2044 346.764 60V411.667Z"
        fill="#E6E6E6"
      />
      <path
        d="M386.371 412H301.343C301.182 412 301.027 411.93 300.913 411.805C300.799 411.68 300.735 411.51 300.735 411.333C300.735 411.156 300.799 410.987 300.913 410.862C301.027 410.737 301.182 410.667 301.343 410.667H386.371C386.532 410.667 386.687 410.737 386.801 410.862C386.915 410.987 386.979 411.156 386.979 411.333C386.979 411.51 386.915 411.68 386.801 411.805C386.687 411.93 386.532 412 386.371 412Z"
        fill="#CCCCCC"
      />
      <path
        d="M384.693 103H305.161C304.437 102.999 303.742 102.683 303.23 102.12C302.717 101.558 302.429 100.795 302.428 100V74.6667C302.429 73.8713 302.717 73.1088 303.23 72.5464C303.742 71.9839 304.437 71.6676 305.161 71.6667H384.699C385.165 71.667 385.624 71.798 386.031 72.0472L406.599 84.6465C407.023 84.9062 407.376 85.2851 407.622 85.7444C407.868 86.2036 407.999 86.7266 408 87.2595C408.001 87.7924 407.873 88.316 407.628 88.7764C407.384 89.2369 407.032 89.6175 406.609 89.8793L386.036 102.613C385.626 102.866 385.164 103 384.693 103V103Z"
        fill="#F9FE19"
      />
      <path
        d="M377.436 157.667H297.904C297.433 157.666 296.971 157.533 296.561 157.28L275.988 144.546C275.565 144.284 275.213 143.904 274.969 143.443C274.724 142.983 274.596 142.459 274.597 141.926C274.598 141.393 274.729 140.87 274.975 140.411C275.221 139.952 275.574 139.573 275.998 139.313L296.566 126.714C296.973 126.465 297.432 126.334 297.898 126.333H377.436C378.16 126.334 378.855 126.651 379.367 127.213C379.88 127.775 380.168 128.538 380.169 129.333V154.667C380.168 155.462 379.88 156.225 379.367 156.787C378.855 157.349 378.16 157.666 377.436 157.667Z"
        fill="#E6E6E6"
      />
      <path
        d="M377.739 211.333H298.207C297.737 211.333 297.275 211.2 296.865 210.947L276.292 198.213C275.869 197.951 275.517 197.57 275.273 197.11C275.028 196.649 274.9 196.126 274.901 195.593C274.902 195.06 275.032 194.537 275.279 194.078C275.525 193.618 275.878 193.239 276.302 192.98L296.87 180.381C297.277 180.131 297.735 180 298.202 180H377.739C378.464 180.001 379.159 180.317 379.671 180.88C380.184 181.442 380.472 182.205 380.473 183V208.333C380.472 209.129 380.184 209.891 379.671 210.454C379.159 211.016 378.464 211.332 377.739 211.333V211.333Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};

export default Svg404;
