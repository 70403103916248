import React from "react";

const ContactIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 511.945 511.945"
      viewBox="0 0 511.945 511.945"
      xmlns="http://www.w3.org/2000/svg"
      className="fi-icon"
    >
      <g id="XMLID_1300_">
        <g id="XMLID_80_">
          <path
            id="XMLID_89_"
            d="m470.896 192.153-32.924-32.938v-65.27c0-22.056-17.944-40-40-40h-65.2l-50.973-51.003c-1.875-1.875-4.418-2.93-7.07-2.931l-37.253-.011c-.001 0-.002 0-.003 0-2.651 0-5.194 1.053-7.069 2.927l-51.05 51.019h-65.382c-22.056 0-40 17.944-40 40v65.374l-32.902 32.826c-9.735 9.73-15.097 22.669-15.097 36.434v231.855c0 13.875 5.524 26.476 14.476 35.747.193.231.394.458.611.676.216.216.442.415.672.607 9.271 8.954 21.874 14.48 35.751 14.48h133.49c5.523 0 10-4.478 10-10s-4.477-10-10-10h-133.491c-5.047 0-9.816-1.199-14.05-3.318l117.869-117.869 38.374 38.351c9.728 9.723 22.658 15.076 36.411 15.076h.016c13.759-.004 26.692-5.366 36.418-15.098l38.214-38.238 117.778 117.778c-4.233 2.118-9.003 3.318-14.05 3.318h-133.489c-5.523 0-10 4.478-10 10s4.477 10 10 10h133.491c13.875 0 26.478-5.525 35.749-14.478.231-.193.458-.393.674-.609.218-.218.418-.445.612-.677 8.951-9.271 14.475-21.872 14.475-35.746v-231.87c-.001-13.752-5.355-26.683-15.078-36.412zm-32.923-4.648 18.777 18.786c1.734 1.735 3.233 3.641 4.498 5.673l-23.275 23.29zm-196.361-167.504 28.969.01 33.914 33.935h-96.849zm-186.41 186.298 18.771-18.728v47.65l-23.269-23.254c1.265-2.031 2.765-3.936 4.498-5.668zm-5.912 268.186c-2.118-4.233-3.317-9.002-3.317-14.049v-224.923l121.182 121.107zm229.085-79.535c-5.95 5.953-13.861 9.232-22.278 9.235-.003 0-.006 0-.009 0-8.413 0-16.323-3.275-22.274-9.223l-139.841-139.754v-161.263c0-11.028 8.972-20 20-20h284c11.028 0 20 8.972 20 20v68.134c-.095.795-.095 1.597 0 2.392v90.794zm187.598 65.486c0 5.047-1.199 9.816-3.317 14.049l-117.782-117.782 121.099-121.173z"
          />
          <path
            id="XMLID_135_"
            d="m255.973 491.942c-2.63 0-5.21 1.07-7.07 2.93s-2.93 4.44-2.93 7.07 1.07 5.21 2.93 7.07c1.86 1.87 4.44 2.93 7.07 2.93s5.21-1.06 7.07-2.93c1.86-1.86 2.93-4.44 2.93-7.07s-1.07-5.21-2.93-7.07c-1.86-1.859-4.44-2.93-7.07-2.93z"
          />
          <path
            id="XMLID_136_"
            d="m255.973 115.883c-52.062 0-94.417 42.354-94.417 94.416 0 52.774 42.935 95.709 95.709 95.709h60.779c5.523 0 10-4.478 10-10s-4.477-10-10-10h-60.779c-41.746 0-75.709-33.963-75.709-75.709 0-41.033 33.383-74.416 74.417-74.416s74.417 33.383 74.417 74.416v20.493c0 7.876-6.408 14.283-14.284 14.283s-14.284-6.407-14.284-14.283v-23.147c0-27.379-22.274-49.653-49.653-49.653-28.79 0-52.211 23.422-52.211 52.212 0 28.789 23.422 52.211 52.211 52.211h15.908c5.523 0 10-4.478 10-10s-4.477-10-10-10h-15.908c-17.761 0-32.211-14.45-32.211-32.212 0-17.761 14.45-32.211 32.211-32.211 16.351 0 29.653 13.303 29.653 29.653v23.147c0 18.904 15.379 34.283 34.284 34.283s34.284-15.379 34.284-34.283v-20.493c-.001-52.062-42.356-94.416-94.417-94.416z"
          />
        </g>
      </g>
    </svg>
  );
};

export default ContactIcon;
