import React from "react";

const SkillIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 64 64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      className="fi-icon"
    >
      <path d="m34.144 36.542 1.963-3.306-5.343-5.343-3.306 1.963c-.868-.458-1.783-.836-2.73-1.128l-.951-3.728h-7.555l-.95 3.729c-.947.292-1.862.67-2.73 1.128l-3.306-1.963-5.343 5.343 1.963 3.306c-.458.868-.836 1.783-1.128 2.73l-3.728.95v7.555l3.729.95c.292.947.67 1.862 1.128 2.73l-1.963 3.306 5.343 5.343 3.306-1.963c.868.458 1.783.836 2.73 1.128l.95 3.728h7.555l.95-3.729c.947-.292 1.862-.67 2.73-1.128l3.306 1.963 5.343-5.343-1.963-3.306c.458-.868.836-1.783 1.128-2.73l3.728-.95v-7.555l-3.729-.95c-.292-.947-.669-1.862-1.127-2.73zm2.856 9.681-3.344.853-.152.564c-.316 1.177-.78 2.3-1.379 3.337l-.292.507 1.76 2.966-3.144 3.144-2.966-1.76-.507.292c-1.037.599-2.16 1.062-3.337 1.379l-.564.152-.852 3.343h-4.445l-.853-3.344-.564-.152c-1.177-.316-2.3-.78-3.337-1.379l-.507-.292-2.966 1.76-3.144-3.144 1.76-2.966-.292-.507c-.599-1.037-1.062-2.16-1.379-3.337l-.152-.564-3.344-.852v-4.445l3.344-.853.152-.564c.316-1.177.78-2.3 1.379-3.337l.292-.507-1.76-2.966 3.144-3.144 2.966 1.76.507-.292c1.037-.599 2.16-1.062 3.337-1.379l.564-.152.852-3.344h4.445l.853 3.344.564.152c1.177.316 2.3.78 3.337 1.379l.507.292 2.966-1.76 3.144 3.144-1.76 2.966.292.507c.599 1.037 1.062 2.16 1.379 3.337l.152.564 3.344.852z" />
      <path d="m20 32c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12zm0 22c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10z" />
      <path d="m20 38c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zm0 10c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" />
      <path d="m60 9h-24c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h2v4.618l9.236-4.618h12.764c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3zm1 17c0 .552-.448 1-1 1h-13.236l-6.764 3.382v-3.382h-4c-.552 0-1-.448-1-1v-14c0-.552.448-1 1-1h24c.552 0 1 .448 1 1z" />
      <path d="m4 19h9.723l6.277 3.767v-3.767h8c1.654 0 3-1.346 3-3v-12c0-1.654-1.346-3-3-3h-24c-1.654 0-3 1.346-3 3v12c0 1.654 1.346 3 3 3zm-1-15c0-.552.448-1 1-1h24c.552 0 1 .448 1 1v12c0 .552-.448 1-1 1h-10v2.233l-3.723-2.233h-10.277c-.552 0-1-.448-1-1z" />
      <path d="m57 23h-2v-8h-2v8h-2v-10h-2v10h-2v-6h-2v6h-2v-4h-2v4h-2v2h20v-12h-2z" />
      <path d="m8 13c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3 1.346 3 3 3zm0-4c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" />
      <path d="m16 13c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3 1.346 3 3 3zm0-4c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" />
      <path d="m24 13c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3 1.346 3 3 3zm0-4c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" />
      <path d="m52 41c-1.956 0-3.871.524-5.563 1.52l-6.03-.716 2.028 4.78c-.941 1.656-1.435 3.517-1.435 5.416 0 6.065 4.935 11 11 11s11-4.935 11-11-4.935-11-11-11zm-5.645 18h11.289c-1.545 1.248-3.508 2-5.645 2s-4.098-.752-5.644-2zm4.315-6.521c-.432-.389-.67-.915-.67-1.479v-1.83c0-1.141.849-2.112 1.891-2.167.548-.032 1.084.164 1.486.547.401.382.623.896.623 1.45v2c0 .564-.238 1.09-.67 1.479l-.33.298v1.841l2.764 1.382c.445.222.791.575 1.003 1h-9.533c.212-.425.559-.778 1.003-1l2.763-1.382v-1.841zm8.307 5.196c-.112-1.482-.973-2.791-2.319-3.464l-1.513-.756c.549-.7.855-1.565.855-2.455v-2c0-1.092-.454-2.149-1.246-2.901-.804-.762-1.844-1.134-2.968-1.094-2.123.111-3.786 1.941-3.786 4.165v1.83c0 .89.306 1.755.854 2.455l-1.513.756c-1.346.673-2.207 1.982-2.319 3.464-1.262-1.55-2.022-3.525-2.022-5.675 0-1.696.483-3.357 1.398-4.803l.28-.443-1.085-2.558 3.303.393.301-.19c1.446-.916 3.107-1.399 4.803-1.399 4.963 0 9 4.037 9 9 0 2.15-.76 4.125-2.023 5.675z" />
    </svg>
  );
};

export default SkillIcon;
